import React from 'react';
import construcaoImg from '../../../assets/img/construcao-prot.svg';
import './ConstrucaoProtocolos.scss';

function ConstrucaoProtocolos() {
  return (
    <section className="section-construcao">
      <div className="section-construcao__info">
        <h1>Construção de protocolos.</h1>
        <p>
          Os protocolos são modelados em linguagem específica para a saúde e estes são transformados
          em interfaces de atendimento por meio de uma engine baseada em
          <br />
          care pathway.
        </p>
      </div>

      <img src={construcaoImg} alt="logo construcao" />
    </section>
  );
}

export default ConstrucaoProtocolos;
