/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';

import './BannerInicial.scss';
import header from '../../../assets/img/header-site.png';
import iconHapvida from '../../../assets/img/icon-hapvida.svg';
import iconEurofarma from '../../../assets/img/icon-eurofarma.svg';
// import iconLivSaude from '../../../assets/img/icon-liv-saude.svg';
// import iconLeveSaude from '../../../assets/img/icon-leve-saude.svg';
import iconUnimed from '../../../assets/img/unimed-logo.png';
import iconAthena from '../../../assets/img/athena-logo.png';
import iconParceirosMedflow from '../../../assets/img/icon-parceiros-medflow.svg';
import ModalB2B from '../../shared/ModalB2B';

function BannerInicial() {
  const [showModal, setShowModal] = useState(false);

  /* Handle close modal action */
  const onCloseModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  return (
    <>
      <header className="main-banner-root">
        <div className="main-banner">
          <div className="main-banner__content">
            <div className="banner">
              <h1 className="banner__title">
                Organize e padronize as condutas em sua instituição.
              </h1>
              <p className="banner__text">
                O Medflow ajuda operadoras,
                hospitais e clínicas a organizar e padronizar as condutas
                com tecnologia e evidências científicas.
              </p>
              <div className="banner__buttons">
                <span
                  role="button"
                  style={{ marginLeft: 0 }}
                  onClick={() => setShowModal(true)}
                  to="/enterprise"
                  className="banner__button banner__button__primary"
                >
                  Agende uma demonstração
                </span>

              </div>
            </div>
            <img className="banner__img" src={header} alt="" />
          </div>
          <div className="main-banner__icons">
            <img src={iconParceirosMedflow} alt="" className="iconParceirosMedflow" />
            <img src={iconEurofarma} alt="" className="iconEurofarma" />
            <img src={iconHapvida} alt="" className="iconHapvida" />
            <img src={iconUnimed} alt="" className="iconUnimed" />
            <img src={iconAthena} alt="" className="iconAthena" />
          </div>
        </div>

      </header>
      <ModalB2B
        show={showModal}
        onClose={onCloseModal}
      />
    </>
  );
}

export default BannerInicial;
