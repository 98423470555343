import React from 'react';
import { Link } from 'react-router-dom';
import './pageNotFound.scss';
import error404 from '../../assets/img/medflow-404.svg';

function PageNotFound() {
  return (
    <div className="container-not-found">
      <div className="box-error">
        <img src={error404} alt="" />
        <p>
          Oops! Esta página não está dentro do nosso fluxo.
          Por favor, atualize para retornar.
        </p>
        <Link to="/" className="redirect">
          <span>Atualizar página</span>
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound;
