import React from 'react';
import './CondutaInterativa.scss';
import conduta from '../../../assets/img/medflow-conduta-interativa-nobg.png';
// import condutaMobile from '../../../assets/img/medflow-conduta-interativa-nb.png';

export default function CondutaInterativa() {
  return (
    <section className="conduta-interativa">
      <div className="conduta-interativa__info">
        <h3>O que é uma conduta interativa?</h3>
        <span>
          É o conhecimento clínico estruturado num algoritmo de apoio à decisão clínica.
        </span>
        <span>
          Ele direciona o atendimento num fluxograma com desdobramentos baseados nas
          evidências coletadas durante um atendimento.
        </span>
      </div>
      <img className="conduta-interativa__img" src={conduta} alt="Conduta Interativa" />
      <img className="conduta-interativa__img-mobile" src={conduta} alt="Conduta Interativa" />
    </section>
  );
}
