/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import closeIcon from '../../assets/img/close_icon.svg';

// eslint-disable-next-line react/prop-types
function ModalExecucao({ show, execucaoData, onClose }) {
  const [firstRender, setFirstRender] = useState(true);
  const modal = useRef(null);
  const modalBody = useRef(null);
  const modalBodyContentFrame = useRef(null);

  const initModal = useCallback(() => {
    const ifrm = modalBodyContentFrame.current;
    if (execucaoData) {
      // eslint-disable-next-line react/prop-types
      ifrm.setAttribute('src', execucaoData.redirect_uri);
      ifrm.setAttribute('id', 'medflowFrame');
    }
  }, [execucaoData]);

  const openModal = () => {
    document.body.classList.toggle('no-overflow');
    modal.current.classList.toggle('modal--open');
    modal.current.classList.toggle('modal--open__centered');
    modalBody.current.classList.toggle('modal__body--open');

    initModal();
  };

  const hideModal = () => {
    modalBody.current.classList.toggle('modal__body--close');
  };

  const closeModalAnimationEnd = (event) => {
    if (event.animationName === 'closeModal') {
      document.body.classList.toggle('no-overflow');
      modal.current.classList.toggle('modal--open');
      modal.current.classList.toggle('modal--open__centered');
      modalBody.current.classList.toggle('modal__body--close');
      modalBody.current.classList.toggle('modal__body--open');
    }
  };

  const onClickClose = (event) => {
    event.preventDefault();
    onClose(event);
  };

  const clickOutside = (event) => {
    if (event.target.id === 'modal-execucao') {
      // onClose(event);
    }
  };

  const iframeExecucao = React.useMemo(
    () => (execucaoData ? (
      <iframe
        ref={modalBodyContentFrame}
        id="medflowFrame"
        title="Meflow App"
          // eslint-disable-next-line react/prop-types
        src={execucaoData?.redirect_uri}
        style={{
          width: '100%',
          height: '100%',
          border: 0,
        }}
      />
    ) : (
      <></>
    )),
    [execucaoData],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!firstRender) {
      return show ? openModal() : hideModal();
    }
    setFirstRender(false);
  }, [show]);

  useEffect(() => {
    const listener = (event) => {
      const {
        origin,
        // eslint-disable-next-line no-unused-vars
        data: { type, content },
      } = event;
      if (origin.endsWith('.medflowapp.com') && type === 'closeMedflowApp') {
        onClose(event);
      }
    };

    if (execucaoData) {
      window.addEventListener('message', listener, false);
    } else {
      window.removeEventListener('message', listener);
    }
  }, [execucaoData]);

  return (
    <div
      id="modal-execucao"
      ref={modal}
      className="modal"
      onAnimationEnd={(event) => closeModalAnimationEnd(event)}
      onClick={(event) => {
        clickOutside(event);
      }}
    >
      <div className="modal__body modal__body__centered" ref={modalBody}>
        <div className="modal-execucao">
          <div className="modal-execucao__header">
            <img
              className="modal-execucao__header__close"
              src={closeIcon}
              onClick={onClickClose}
              alt="Close"
            />
          </div>
          <div className="modal-execucao__body">{iframeExecucao}</div>
        </div>
      </div>
    </div>
  );
}

/** Props Model */
ModalExecucao.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalExecucao;
