import React from 'react';

function Numbers() {
  return (
    <section className="numbers-section">
      <div className="title-board">
        <h2 className="title-board__title">
          Uma solução de alta confiabilidade.
        </h2>
        <span className="title-board__subtitle">
          Testada e aprovada por centenas de médicos em todo país.
        </span>
      </div>

      <div className="number-board">
        <div className="number-board__element">
          <div className="number-board__element__text">
            <h1 className="number-board__element__text_title">+400</h1>
            <span className="number-board__element__text_subtitle">
              Hospitais e clinicas já
              <br />
              usaram o Medflow.
            </span>
          </div>
        </div>
        <hr className="hr-solid" />
        <div className="vl-solid" />
        <div className="number-board__element">
          <div className="number-board__element__text">
            <h1 className="number-board__element__text_title">+25 MI</h1>
            <span className="number-board__element__text_subtitle">
              De atendimentos
              <br />
              realizados com o Medflow
            </span>
          </div>
        </div>
        <div className="vl-solid" />
        <hr className="hr-solid" />
        <div className="number-board__element">
          <div className="number-board__element__text">
            <h1 className="number-board__element__text_title">98%</h1>
            <span className="number-board__element__text_subtitle">
              De adesão dos médicos
              <br />
              à conduta proposta
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Numbers;
