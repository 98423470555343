import React, { useEffect, useState } from 'react';
import BannerComercial from './bannerComercial/BannerComercial';
import Contatos from './contatos/Contatos';
import ModalB2B from '../shared/ModalB2B';
import Formulario from './formulario/Formulario';
import ModalConfirmacao from '../shared/ModalConfirmacao';

function FaleConosco() {
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);

  /* Handle show modal action */
  const showModalEvent = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  /* Handle show modal confirmation action */
  const showModalConfirmacaoEvent = (event) => {
    event.preventDefault();
    setShowModalConfirmacao(true);
  };

  /* Handle close modal action */
  const onCloseModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  /* Handle close modal confirmation action */
  const onCloseModalConfirmacao = (event) => {
    event.preventDefault();
    setShowModalConfirmacao(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <BannerComercial />
      <Formulario showModalEvent={showModalConfirmacaoEvent} />
      <Contatos showModalEvent={showModalEvent} />
      <ModalB2B
        show={showModal}
        onClose={onCloseModal}
        isParceiroMedflow
      />
      <ModalConfirmacao show={showModalConfirmacao} onClose={onCloseModalConfirmacao} />
    </>
  );
}

export default FaleConosco;
