import React from 'react';
import play from '../../../assets/img/play-circle.svg';
import { ReactComponent as Spinner } from '../../../assets/img/spinner.svg';
import ModalExecucao from '../../shared/ModalExecucao';
import './Demonstracao.scss';
import { requestAbrirProtocolo, requestSugerirProtocolos } from './service';

function Demonstracao() {
  const [loadingProtocolo, setLoadingProtocolo] = React.useState(null);
  const [protocolos, setProtocolos] = React.useState([]);
  const [protocoloExecutado, setProtocoloExecutado] = React.useState(null);
  const [showModalExecucao, setShowModalExecucao] = React.useState(false);

  const requestProtocolosData = React.useCallback(async () => {
    try {
      const data = await requestSugerirProtocolos();

      if (data) {
        setProtocolos(data.results);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, []);

  // eslint-disable-next-line no-unused-vars
  const abrirProtocolo = React.useCallback(async (protocolo) => {
    setLoadingProtocolo(protocolo);

    try {
      const data = await requestAbrirProtocolo(protocolo);

      if (data) {
        setProtocoloExecutado(data);
        setShowModalExecucao(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoadingProtocolo(null);
    }
  }, []);

  /* Handle close modal confirmation action */
  const onCloseModalExecucao = (event) => {
    event.preventDefault();
    setProtocoloExecutado(null);
    setShowModalExecucao(false);
  };

  React.useEffect(() => {
    requestProtocolosData();
  }, []);

  return (
    <section className="section-demonstracao">
      <div className="title-board">
        <h2 className="title-board__title">
          Veja uma demonstração do Medflow.
        </h2>
        <div className="title-board__button">
          <img
            className="title-board__img"
            src={play}
            alt="Escolha protocolo"
          />
          Escolha um protocolo e experimente
        </div>
      </div>
      <div className="medflow-demonstracao__list">
        {protocolos.map((protocolo) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            key={protocolo.codigo}
            className={`medflow-demonstracao__item ${
              loadingProtocolo ? 'disabled' : ''
            }`}
            onClick={() => {
              if (!loadingProtocolo) {
                abrirProtocolo(protocolo);
              }
            }}
          >
            <img
              className="medflow-demonstracao__item__icon"
              src={protocolo.imagem_url}
              alt="Imagem protocolo"
            />
            <div className="medflow-demonstracao__item__text">
              {protocolo.nome}
            </div>
            {loadingProtocolo?.codigo === protocolo.codigo && (
              <div className="medflow-demonstracao__item__loading">
                <Spinner />
              </div>
            )}
          </div>
        ))}
      </div>
      <ModalExecucao
        show={showModalExecucao}
        execucaoData={protocoloExecutado}
        onClose={onCloseModalExecucao}
      />
    </section>
  );
}

Demonstracao.propTypes = {};

export default Demonstracao;
