import React from 'react';

import '../../landing/BannerInicial/BannerInicial.scss';
import './header.scss';
import header from '../../../assets/img/fluxo-medflow.png';
import headerMobile from '../../../assets/img/fluxo-medflow-mobile.png';

function Header() {
  // tecnologia-banner__container
  function bannerTecnologia({ className }) {
    return (
      <div className={className}>
        <div className={`${className}__text`}>
          <h1 className="banner__title">
            Construa protocolos de forma rápida e prática
          </h1>
          <p className="banner__text">
            Plataforma low-code com care pathway para construir condutas interativas.
          </p>
        </div>
      </div>
    );
  }

  return (
    <header className="tecnologia-banner">
      {bannerTecnologia({ className: 'tecnologia-banner__container' })}
      <img className="tecnologia-banner__img" src={header} alt="" />
      <img className="tecnologia-banner__img__mobile" src={headerMobile} alt="" />
      {bannerTecnologia({ className: 'tecnologia-banner__container__mobile' })}
    </header>
  );
}

export default Header;
