/* eslint-disable no-debugger */
import React, { useEffect, useRef, useState } from 'react';
import {
  Link, useLocation, useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import logoMedflow from '../../assets/img/logo_medflow.svg';
import hambuguerIcon from '../../assets/img/hamburguer_icon.svg';
import sigaInsta from '../../assets/img/menu_siga_instagram.svg';

import closeButton from '../../assets/img/close_icon.svg';
import ModalB2B from '../shared/ModalB2B';

function ProductsSelection({ isProduct }) {
  const history = useHistory();
  const goToB2C = () => {
    history.push('/personal');
  };
  const goToB2B = () => {
    history.push('/enterprise');
  };
  return (
    <div
      className={
        !isProduct ? 'products__selection' : 'products__selection products__selection-b2c'
      }
    >
      <div className="options">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="products__selection-content product-2" onClick={goToB2B}>
          <div className="products__selection-text">
            <h3 className="products__selection__primary-text">Medflow Enterprise</h3>
            <p className="products__selection__secondary-text">Para unidades de saúde</p>
          </div>
          <p
            type="button"
            className="products__selection-button-primary__enterprise"
          >
            Saiba mais
          </p>
        </div>
      </div>
      <div className="options">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className="products__selection-content product-1"
          onClick={goToB2C}
        >
          <div className="products__selection-text">
            <h3 className="products__selection__primary-text">Medflow Personal</h3>
            <p className="products__selection__secondary-text">Para médicos</p>
          </div>
          <p
            to="/personal"
            className="products__selection-button-primary"
          >
            Saiba mais
          </p>
        </div>
      </div>

    </div>
  );
}

ProductsSelection.propTypes = {
  isProduct: PropTypes.bool,
};

ProductsSelection.defaultProps = {
  isProduct: false,
};

function Nav() {
  const NOSSOS_PRODUTOS_INDEX = 1;
  /* Menu DOM objects */
  const mobileMenuButton = useRef(null);
  const mobileMenuBody = useRef(null);
  const mobileMenuBodyHeader = useRef(null);
  const mobileMenuBodyFooter = useRef(null);
  const WebProductsAnimatedButton = useRef(null);

  /* Menu state */
  const [state, setState] = useState({
    isOpen: false,
  });

  /* Active NavItems state */
  const [activeId, setActiveId] = useState();

  /* isProduct state */
  const [isProduct, setIsProduct] = useState(false);

  const [isB2C, setIsB2C] = useState(false);

  /* handle the isProduct state */
  const updateIsProduct = (value) => {
    setIsProduct(value);
  };

  /* Modal Enterprise */
  const [showModal, setShowModal] = useState(false);

  /* Handle close modal action */
  const onCloseModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  /** Listener for animation opening and closing mobile menu */
  const menuAnimationStart = (event) => {
    if (event.animationName === 'closeMobileMenuBody') {
      mobileMenuBodyHeader.current.classList.add('invisible');
      mobileMenuBodyFooter.current.classList.add('invisible');
      mobileMenuBody.current.classList.add('p0');
    }
  };

  /** Listener for animation opening and closing mobile menu */
  const menuAnimationEnd = (event) => {
    if (event.animationName === 'openMobileMenuBody') {
      mobileMenuBody.current.classList.remove('p0');

      mobileMenuBodyHeader.current.classList.remove('invisible');
      mobileMenuBodyFooter.current.classList.remove('invisible');

      mobileMenuBodyHeader.current.classList.add('visible');
      mobileMenuBodyFooter.current.classList.add('visible');
    } else if (event.animationName === 'closeMobileMenuBody') {
      mobileMenuBody.current.classList.remove('close');
    }
  };

  /** Listener for mobile menu mobile open/close animation */
  const openCloseMenuMobile = () => {
    setState({ ...state, isOpen: !state.isOpen });

    if (!state.isOpen) {
      mobileMenuButton.current.setAttribute('src', closeButton);
      mobileMenuBody.current.classList.remove('close');
      mobileMenuBody.current.classList.add('show');
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'auto';
    mobileMenuButton.current.setAttribute('src', hambuguerIcon);
    mobileMenuBody.current.classList.remove('show');
    mobileMenuBody.current.classList.add('close');
  };

  const location = useLocation();
  const routesMap = {
    'quem-somos': 0,
    // eslint-disable-next-line quote-props
    'b2c': 1,
    tecnologia: 2,
    'fale-conosco': 3,
  };

  const updateActiveId = (i) => {
    if (isProduct) setActiveId(i);
    if (i !== NOSSOS_PRODUTOS_INDEX) {
      WebProductsAnimatedButton.current.classList.remove('rotate');
    }
  };

  useEffect(() => {
    const locationIsB2C = window.location.pathname.includes('/personal');
    setIsB2C(locationIsB2C);

    const route = window.location.pathname.split('/')[1];
    setActiveId(routesMap[route]);
  }, [location]);

  /* navBar links */
  const navbarLinks = [
    {
      id: 3,
      element: (
        <Link to="/tecnologia" className="link link__navbar">
          Nossa tecnologia
        </Link>
      ),
    },
    {
      id: 4,
      element: (
        <Link to="/fale-conosco" className="link link__navbar">
          Fale conosco
        </Link>
      ),
    },
  ];

  return (
    <nav className="navbar">
      <div className={!isProduct ? 'navbar__body' : 'navbar__body-b2c'}>
        <Link
          to="/"
          onClick={() => {
            updateIsProduct(false);
            setActiveId(-1);
          }}
        >
          <img src={logoMedflow} alt="Medflow" />
        </Link>
        <ul
          className={
            !isProduct ? 'navbar__menu justify-content-end' : 'navbar__menu'
          }
        >
          {navbarLinks.map((link, i) => (
            <li
              key={link.id}
              onClick={() => updateActiveId(i)}
              className={activeId === i ? 'active' : ''}
            >
              {link.element}
            </li>
          ))}
          <li>
            {isB2C ? (
              <a
                href="https://medflowapp.com/"
                className="button button--primary-tint button--rounded button--personal"
              >
                Entrar
              </a>
            ) : null}
          </li>

        </ul>

        <div className="navbar__menu-mobile">
          <img
            src={hambuguerIcon}
            id="mobile-menu-button"
            ref={mobileMenuButton}
            alt=""
            onClick={openCloseMenuMobile}
          />

          <div
            id="menu-mobile-body"
            ref={mobileMenuBody}
            className="navbar__menu-mobile__body"
            onAnimationStart={(event) => menuAnimationStart(event)}
            onAnimationEnd={(event) => menuAnimationEnd(event)}
          >
            <div
              id="menu-mobile-body-header"
              className="navbar__menu-mobile__body__header"
              ref={mobileMenuBodyHeader}
            >
              <Link
                className="link mobile-menu-trigger"
                to="/tecnologia"
                onClick={openCloseMenuMobile}
              >
                <h1>Nossa tecnologia</h1>
              </Link>
              <Link
                className="link mobile-menu-trigger"
                to="/fale-conosco"
                onClick={openCloseMenuMobile}
              >
                <h1>Fale conosco</h1>
              </Link>
            </div>
            <div
              id="menu-mobile-body-footer"
              className="navbar__menu-mobile__body__footer"
              ref={mobileMenuBodyFooter}
            >
              <a href="https://www.instagram.com/medflowapp/">
                <img src={sigaInsta} alt="Siga o Medflow" />
              </a>
            </div>
          </div>
        </div>
        <ModalB2B show={showModal} onClose={onCloseModal} />
      </div>
    </nav>
  );
}

export default Nav;
