import React, { useEffect, useState } from 'react';
import BannerInicial from './BannerInicial';
// import ParaUnidadesDeSaude from './ParaUnidadesDeSaude';
// import ParaMedicos from './ParaMedicos';
// import Numbers from './Numbers';
// import ModalB2B from '../shared/ModalB2B';
import ContentB2B from '../b2b/ContentB2B';

function Landing() {
  const [setShowModal] = useState(false);
  const [firstRender, setfirstRender] = useState(true);

  /* Handle show modal action */
  const showModalEvent = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    if (firstRender) {
      window.scrollTo(0, 0);
      setfirstRender(false);
    }
  });

  return (
    <>
      <BannerInicial openModal={showModalEvent} />
      <ContentB2B />
    </>
  );
}

export default Landing;
