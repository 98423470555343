import axios from 'axios';
import * as jose from 'jose';

const alg = 'RS256';

const payloadUser = {
  iss: process.env.REACT_APP_DEFAULT_ISS,
  sub: 'demonstracao',
  name: 'Demonstração',
  // Signing a token with 20 minutes of expiration
};
const payloadClient = {
  iss: process.env.REACT_APP_DEFAULT_ISS,
  sub: process.env.REACT_APP_DEFAULT_ISS,
};

const getJWTAuthPrivateKey = async () => {
  const pkcs8 = process.env.REACT_APP_PRIVATE_KEY;
  const privateKey = await jose.importPKCS8(pkcs8, alg);

  return privateKey;
};

const getAtendimentoRequestData = () => {
  const Encounter = {
    id: '725b77e2-bd35-4d68-acca-c3c3d9b79750',
    status: 'in-progress',
    class: {
      code: 'AMB',
      system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
      display: 'ambulatorial',
    },
    subject: {
      reference: '#pat-1',
    },
    location: [
      {
        location: {
          reference: '#loc-1',
        },
      },
    ],
    contained: [
      {
        id: 'pat-1',
        name: [
          {
            use: 'official',
            text: 'João da Silva',
          },
          {
            use: 'usual',
            text: 'João da Silva',
          },
        ],
        gender: 'male',
        birthDate: '1971-07-02',
        resourceType: 'Patient',
      },
      {
        id: 'pract-1',
        name: [
          {
            use: 'official',
            text: 'Maria dos Santos Teixeira',
          },
        ],
        gender: 'female',
        resourceType: 'Practitioner',
      },
      {
        id: 'practrole-1',
        specialty: {
          coding: [
            {
              code: '225125',
              system:
                'http://healthit.medflowapp.com/fhir/CodeSystem/tuss-tab-24',
              display: 'Clínica geral',
            },
          ],
        },
        practitioner: {
          reference: '#pract-1',
        },
        resourceType: 'PractitionerRole',
      },
      {
        id: 'org-1',
        name: 'SUS',
        resourceType: 'Organization',
      },
      {
        id: 'loc-1',
        name: 'Hospital São Jorge',
        resourceType: 'Location',
      },
    ],
    participant: [
      {
        individual: {
          reference: '#practrole-1',
        },
      },
    ],
    resourceType: 'Encounter',
    serviceProvider: {
      reference: '#org-1',
    },
  };

  return Encounter;
};

const getTokenClient = async () => {
  const secret = await getJWTAuthPrivateKey();
  const token = await new jose.SignJWT()
    .setProtectedHeader({ alg })
    .setIssuedAt(new Date())
    .setIssuer(payloadClient.iss)
    .setSubject(payloadClient.sub)
    .setExpirationTime('10min')
    .sign(secret);

  return token;
};

const getTokenUser = async () => {
  const secret = await getJWTAuthPrivateKey();
  const token = await new jose.SignJWT({ name: payloadUser.name })
    .setProtectedHeader({ alg })
    .setIssuedAt(new Date())
    .setIssuer(payloadUser.iss)
    .setSubject(payloadUser.sub)
    .setExpirationTime('10min')
    .sign(secret);

  return token;
};

export async function requestAbrirProtocolo(protocolo) {
  const token = await getTokenUser();
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/enterprise/protocolos/${protocolo.codigo}/abrir/`,
    getAtendimentoRequestData(),
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return data;
}

export async function requestSugerirProtocolos() {
  const token = await getTokenClient();
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/enterprise/protocolos?fields=abrir_url,codigo,nome,imagem_url`,
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return data;
}
