import React from 'react';
import PropTypes from 'prop-types';
import './CardInformacao.scss';

function CardInformacao({ icon, title, text }) {
  return (
    <div className="card-informacao">
      <img src={icon} alt="" />
      <h1>{title}</h1>
      <p>
        {text}
      </p>
    </div>
  );
}

CardInformacao.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CardInformacao;
