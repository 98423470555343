import React from 'react';
import PropTypes from 'prop-types';
import beneficiosImg from '../../../assets/img/doctor-man-beneficios.png';
import './Beneficios.scss';

function Beneficios({ openModal }) {
  return (
    <section className="section-beneficios">
      <div className="section-body">
        <h3 className="section-beneficios__primary-text__mobile">
          Mais benefícios do Medflow para a sua unidade de saúde.
        </h3>
        <img
          className="beneficios-img beneficios-img__mobile"
          src={beneficiosImg}
          alt=""
        />
        <ul className="bullet-list__mobile">
          <li>
            <span>
              Padronize o atendimento e defina quais protocolos se encaixam nas
              necessidades da sua unidade de saúde.
            </span>
          </li>
          <li>
            <span>
              Tome decisões administrativas baseadas em dados disponibilizados
              pela visão gerencial do uso dos protocolos.
            </span>

          </li>
          <li>
            <span>
              Garanta que os pacientes da unidade sejam cuidados pela inteligência
              mais confiável documentada no protocolo.
            </span>

          </li>
        </ul>

        <p className="saiba-mais__mobile" onClick={openModal}>
          <span>Saiba mais</span>
        </p>

        <div className="section-body__web">
          <h3 className="section-unidade-saude__primary-text">
            Mais benefícios do Medflow para a sua unidade de saúde.
          </h3>
          <div>
            <ul className="bullet-list">
              <li>
                <span>
                  Padronize o atendimento e defina quais protocolos se encaixam nas
                  necessidades da sua unidade de saúde.
                </span>
              </li>
              <li>
                <span>
                  Tome decisões administrativas baseadas em dados disponibilizados
                  pela visão gerencial do uso dos protocolos.
                </span>
              </li>
              <li>
                <span>
                  Garanta que os pacientes da unidade sejam cuidados pela inteligência
                  mais confiável documentada no protocolo.
                </span>
              </li>
            </ul>
            <p className="saiba-mais" onClick={openModal}>
              <span>Saiba mais</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

Beneficios.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default Beneficios;
