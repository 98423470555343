import React from 'react';
import visaoGerencialLogo from '../../../assets/img/visao-gerencial-logo.svg';
import './VisaoGerencial.scss';

export default function VisaoGerencial() {
  return (
    <section className="section-visao-gerencial">
      <img src={visaoGerencialLogo} className="img-desktop" alt="Visao Gerencial" />
      <div className="section-visao-gerencial__info">
        <h1>Visão Gerencial.</h1>
        <img src={visaoGerencialLogo} className="img-mobile" alt="Visao Gerencial" />
        <ul className="bullet-list">
          <li>
            Indicadores de adesão e aderência.
          </li>
          <li>
            Melhoria contínua na qualidade do atendimento.
          </li>
          <li>
            Informações estratégicas para a tomada de decisão.
          </li>
        </ul>

        <ul className="bullet-list__mobile">
          <li>
            Indicadores de adesão e aderência.
          </li>
          <li>
            Melhoria contínua na qualidade do atendimento.
          </li>
          <li>
            Informações estratégicas para a tomada de decisão.
          </li>
        </ul>
      </div>
    </section>
  );
}
