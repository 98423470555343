import React, { useEffect, useRef, useState } from 'react';

import InputMask from 'react-input-mask';
import './formulario.scss';
import PropTypes from 'prop-types';

function Formulario({ showModalEvent }) {
  const [form, setForm] = useState({
    nome: '', email: '', telefone: '', mensagem: '',
  });

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const resetForm = () => {
    setForm({
      nome: '', email: '', telefone: '', empresa: '', mensagem: '',
    });
  };

  const submitButton = useRef(null);

  const onSubmit = (event) => {
    event.preventDefault();
    resetForm();
    fetch(
      'https://wj3j97pilg.execute-api.sa-east-1.amazonaws.com/stable/contacts',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: form.nome,
          email: form.email,
          phone: form.telefone,
          message: form.mensagem,
          contact_type: 'CONTACT_MEDFLOW_ENTERPRISE',
        }),
      },
    ).then(() => {
      showModalEvent(event);
    });
  };

  useEffect(() => {
    submitButton.current.disabled = true;
    if (form.nome && form.email && form.telefone && form.mensagem) {
      submitButton.current.disabled = false;
    }
  }, [form]);
  return (
    <div className="container-formularios">
      <div className="box-texts">
        <h1>Fale conosco</h1>
        <p>Preencha o formuláro e em breve entraremos em contato para mais detalhes.</p>
      </div>
      <form className="container-inputs" onSubmit={onSubmit}>
        <div className="box-one">
          <div className="box-input">
            <span>Nome</span>
            <input type="text" name="nome" value={form.nome} onChange={handleChange} />
          </div>

          <div className="box-input">
            <span>Email</span>
            <input type="email" name="email" value={form.email} onChange={handleChange} />
          </div>

          <div className="box-input">
            <span>Telefone</span>
            <InputMask
              mask="(99) 99999-9999"
              onChange={handleChange}
              value={form.telefone}
              id="formInputTelefone"
              name="telefone"
              type="text"
            />
          </div>
        </div>

        <div className="box-two">
          <div className="box-input">
            <span>Mensagem</span>
            <textarea value={form.mensagem} name="mensagem" onChange={handleChange} />
          </div>

          <button type="submit" ref={submitButton}>Enviar</button>
        </div>
      </form>
      <div
        className="modal"
      />

    </div>
  );
}

Formulario.propTypes = {
  showModalEvent: PropTypes.func.isRequired,
};

export default Formulario;
