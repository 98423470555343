import React from 'react';
import { Link } from 'react-router-dom';
import instagram from '../../assets/img/instagram-white.svg';
import linkedin from '../../assets/img/linkedin-white.svg';
import logoMedflowWhite from '../../assets/img/logo-medflow-white.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__links">
        <div className="footer__links__download">
          <div className="footer__links__item__title">
            <img src={logoMedflowWhite} alt="Logo" className="footer__logo" />
          </div>
          <span className="footer__copyright">
            Copyright
            {' '}
            {new Date().getFullYear()}
            {' '}
            Medflow Tech - Todos os direitos reservados.
          </span>
        </div>
        <div className="footer__links__itens-end">
          <div className="footer__links__item">
            <Link to="/fale-conosco" className="footer__links__item__link">Fale conosco</Link>
            <a
              href="mailto:contato@medflow.tech"
              className="footer__links__item__link"
            >
              contato@medflowtech.com
            </a>
            <div className="footer__medias">
              <div className="footer__medias__logos__logo">
                <a href="https://www.linkedin.com/company/medflow-tech">
                  <img src={linkedin} alt="Instagram" />
                </a>
              </div>
              <div className="footer__medias__logos__logo">
                {/* eslint-disable-next-line */}
                <a href="https://www.instagram.com/medflowapp/">
                  <img src={instagram} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
