import { React } from 'react';
import './IntegracaoSistema.scss';

import integracaoImg from '../../../assets/img/integracao-sistema.svg';
import integracaoMobileImg from '../../../assets/img/integra-sistema-mobile.svg';
import frequenciaIcon from '../../../assets/img/frequencia.svg';
import heartIcon from '../../../assets/img/heart.svg';
import leftAngleIcon from '../../../assets/img/left-angle.svg';
import rightAngleIcon from '../../../assets/img/right-angle.svg';

function IntegracaoSistema() {
  return (
    <div className="section-integracao-sistema">
      <div className="section-integracao-body">
        <div className="section-integracao-sistema__icons__mobile">
          <img
            className="section-integracao-sistema__icons-default"
            src={frequenciaIcon}
            alt=""
          />
          <img
            className="section-integracao-sistema__icons-default"
            src={heartIcon}
            alt=""
          />
          <img
            className="section-integracao-sistema__icons-angle"
            src={leftAngleIcon}
            alt=""
          />
          <img
            className="section-integracao-sistema__icons-angle"
            src={rightAngleIcon}
            alt=""
          />
        </div>
        <h3 className="section-integracao-sistema__primary-text__mobile">
          Integre seu sistema com o Medflow de forma simples.
        </h3>
        <p className="section-integracao-sistema__secondary-text__mobile">
          O Medflow reflete as condutas realizadas dentro do seu prontuário. E
          você ainda conta com:
        </p>
        <img
          className="section-integracao-sistema-img__mobile"
          src={integracaoMobileImg}
          alt="integracao"
        />
        <ul className="bullet-list__mobile">
          <li>Interoperabilidade com HL7 FHIR.</li>
          <li>Integração simples e rápida.</li>
          <li>
            Documentação disponibilizada.
            <a target="_blank" href="https://docs.medflowtech.com/" rel="noreferrer noopener">Veja aqui</a>
          </li>
        </ul>
        <div className="section-integracao-body__web">
          <div className="section-integracao-sistema__text">
            <div className="section-integracao-sistema__icons">
              <img
                className="section-integracao-sistema__icons-default"
                src={frequenciaIcon}
                alt=""
              />
              <img
                className="section-integracao-sistema__icons-default"
                src={heartIcon}
                alt=""
              />
              <img
                className="section-integracao-sistema__icons-angle"
                src={leftAngleIcon}
                alt=""
              />
              <img
                className="section-integracao-sistema__icons-angle"
                src={rightAngleIcon}
                alt=""
              />
            </div>
            <h3 className="section-integracao-sistema__primary-text">
              Integre seu sistema com o Medflow de forma simples.
            </h3>
            <p className="section-integracao-sistema__secondary-text">
              O Medflow reflete as condutas realizadas dentro do seu prontuário.
              E você ainda conta com:
            </p>
            <ul className="bullet-list">
              <li>
                <span>
                  Interoperabilidade com HL7 FHIR.
                </span>
              </li>
              <li>Integração simples e rápida.</li>
              <li>
                Documentação disponibilizada.
                <a href="https://docs.medflowtech.com/" target="_blank" rel="noreferrer noopener">Veja aqui</a>
              </li>
            </ul>
          </div>
          <img
            className="section-integracao-sistema-img"
            src={integracaoImg}
            alt="integracao"
          />
        </div>
      </div>
    </div>
  );
}

export default IntegracaoSistema;
