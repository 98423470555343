/* eslint-disable react/jsx-filename-extension */

import 'swiper/scss';
import 'swiper/scss/pagination';
import './sass/main.scss';

import React from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import PageNotFound from './components/pageNotFound/PageNotFound';
// import ContentB2C from './components/b2c/ContentB2C';
// import ContentB2B from './components/b2b/ContentB2B';
// import QuemSomos from './components/quemSomos/QuemSomos';
import FaleConosco from './components/faleConosco/FaleConosco';
import Landing from './components/landing/Landing';
import Footer from './components/layout/Footer';
import Nav from './components/layout/Nav';
import ContentTecnologia from './components/tecnologia/ContentTecnologia';

function App() {
  return (
    <Router>
      <Nav />
      <Switch>
        <Route exact path="/" component={Landing} />
        {/* <Route path="/personal" component={ContentB2C} /> */}
        {/* <Route path="/enterprise" component={ContentB2B} /> */}
        {/* <Route path="/quem-somos" component={QuemSomos} /> */}
        <Route path="/fale-conosco" component={FaleConosco} />
        <Route path="/tecnologia" component={ContentTecnologia} />
        <Route component={PageNotFound} />
        <Redirect path="/*" to="/" />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
