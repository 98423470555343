/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';
import closeIcon from '../../assets/img/close_icon.svg';

function ModalB2B({
  show, onClose, isParceiroMedflow, initialData,
}) {
  const [firstRender, setFirstRender] = useState(true);

  const [form, setForm] = useState({
    nome: '',
    email: '',
    telefone: '',
    empresa: '',
  });

  const modal = useRef(null);
  const modalBody = useRef(null);
  const inputName = useRef(null);
  const empresarialFormContainer = useRef(null);
  const empresarialFormSuccessFeedbackContainer = useRef(null);
  const submitButton = useRef(null);

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const resetForm = () => {
    setForm({
      nome: '',
      email: '',
      telefone: '',
      empresa: '',
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    resetForm();

    fetch(
      'https://wj3j97pilg.execute-api.sa-east-1.amazonaws.com/stable/contacts',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: form.nome,
          email: form.email,
          empresa: form.empresa,
          phone: form.telefone,
          contact_type: 'CONTACT_MEDFLOW_ENTERPRISE',
        }),
      },
    ).then(() => {
      /** Exibe o feedback de sucesso no formulário */
      empresarialFormContainer.current.style.display = 'none';
      empresarialFormSuccessFeedbackContainer.current.style.display = 'block';
    });
  };

  const openModal = () => {
    document.body.classList.toggle('no-overflow');
    modal.current.classList.toggle('modal--open');
    modalBody.current.classList.toggle('modal__body--open');
    inputName.current.focus();
  };

  const hideModal = () => {
    modalBody.current.classList.toggle('modal__body--close');
    /** Exibe novamente o formulário */
    empresarialFormContainer.current.style.display = 'block';
    empresarialFormSuccessFeedbackContainer.current.style.display = 'none';
  };

  const closeModalAnimationEnd = (event) => {
    if (event.animationName === 'closeModal') {
      document.body.classList.toggle('no-overflow');
      modal.current.classList.toggle('modal--open');
      modalBody.current.classList.toggle('modal__body--close');
      modalBody.current.classList.toggle('modal__body--open');
    }
  };

  const onClickClose = (event) => {
    event.preventDefault();
    onClose(event);
  };

  const clickOutside = (event) => {
    if (event.target.id === 'modal-empresarial') {
      onClose(event);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (initialData) { setForm({ ...form, email: initialData.email }); }

    if (!firstRender) {
      return show ? openModal() : hideModal();
    }

    setFirstRender(false);
    // eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    submitButton.current.disabled = true;
    if (isParceiroMedflow && form.nome && form.email && form.telefone) {
      submitButton.current.disabled = false;
      return;
    }
    if (form.nome && form.empresa && form.email && form.telefone) {
      submitButton.current.disabled = false;
    }
  }, [form]);

  return (
    <div
      id="modal-empresarial"
      ref={modal}
      className="modal"
      onAnimationEnd={(event) => closeModalAnimationEnd(event)}
      onClick={(event) => {
        clickOutside(event);
      }}
    >
      <div className="modal__body" ref={modalBody}>
        <div className="contato-form">
          <div className="contato-form__header">
            <img
              className="contato-form__header__close"
              src={closeIcon}
              onClick={onClickClose}
              alt="Close"
            />
          </div>
          <div className="contato-form__body">
            <div id="contatoFormContainer" ref={empresarialFormContainer}>
              <h1 className="contato-form__body__title">
                {isParceiroMedflow
                  ? 'Torne-se um parceiro Medflow.'
                  : 'Conheça o Medflow Enterprise.'}
              </h1>
              <h2 className="contato-form__body__subtitle">
                Preencha o formuláro e em breve entraremos em contato para mais
                detalhes.
              </h2>

              <form
                id="empresarialForm"
                className="contato-form__body__form"
                onSubmit={onSubmit}
              >
                <div className="form-group">
                  <label>Seu nome</label>
                  <input
                    id="formInputName"
                    ref={inputName}
                    value={form.nome}
                    name="nome"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
                {!isParceiroMedflow && (
                  <div className="form-group">
                    <label>Empresa</label>
                    <input
                      id="formInputEmpresa"
                      name="empresa"
                      type="text"
                      value={form.empresa}
                      onChange={handleChange}
                    />
                  </div>
                )}

                <div className="form-group">
                  <label>Seu e-mail</label>
                  <input
                    id="formInputEmail"
                    value={form.email}
                    name="email"
                    type="email"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="formInputTelefone">Seu telefone</label>
                  <InputMask
                    mask="(99) 99999-9999"
                    onChange={handleChange}
                    value={form.telefone}
                    id="formInputTelefone"
                    name="telefone"
                    type="text"
                  />
                </div>

                <div className="contato-form__body__form__actions">
                  <button
                    type="submit"
                    id="formSubmitButton"
                    className="button button--primary"
                    ref={submitButton}
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
            <div
              id="feedbackSucessoContainer"
              ref={empresarialFormSuccessFeedbackContainer}
            >
              <h1>Obrigado!</h1>
              <p>
                Obrigado pelo interesse! Em até 24 horas entraremos em contato!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/** Props Model */
ModalB2B.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isParceiroMedflow: PropTypes.bool,
  initialData: PropTypes.shape({
    email: PropTypes.string,
  }),
};

ModalB2B.defaultProps = {
  isParceiroMedflow: false,
  initialData: {
    email: '',
  },
};

export default ModalB2B;
