import { React } from 'react';

import currencyIcon from '../../../assets/img/currency.svg';
import timeIcon from '../../../assets/img/time.svg';
import smileIcon from '../../../assets/img/smile.svg';

import './principaisVantagens.scss';

function PrincipaisVantagens() {
  return (
    <div className="container-principais-vantagens">
      <div className="title-principais-vantagens">
        <h1>Principais vantagens para a sua clínica ou hospital.</h1>
      </div>
      <div className="box-cards-principais-vantagens">
        <div className="card-principais-vantagens">
          <img src={smileIcon} alt="" />
          <h1>Adesão aos protocolos.</h1>
          <p>
            Ofereça uma experiência útil e intuitiva a sua equipe médica, e garanta que
            consumam protocolos baseados
            em evidências científicas.
          </p>
        </div>
        <div className="card-principais-vantagens">
          <img src={timeIcon} alt="" />
          <h1>Otimização de tempo de atendimento.</h1>
          <p>
            Médicos perdem até 59% do tempo de atendimento procurando
            informações clínicas. O Medflow reduz a perda de tempo da sua equipe.
          </p>
        </div>
        <div className="card-principais-vantagens">
          <img src={currencyIcon} alt="" />
          <h1>Eliminação de gastos desnecessários.</h1>
          <p>
            Garanta que seu corpo médico tenha as devidas informações para
            evitar a solicitação de exames e procedimentos indevidos.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrincipaisVantagens;
