import React from 'react';
import Header from './Header';
import ConstrucaoProtocolos from './ConstrucaoProtocolos';
import Diferenciais from './Diferenciais';
import VisaoGerencial from './VisaoGerencial';
import IntegracaoSistema from '../b2b/IntegracaoSistema/IntegracaoSistema';

function ContentTecnologia() {
  return (
    <>
      <Header />
      <Diferenciais />
      <ConstrucaoProtocolos />
      <VisaoGerencial />
      <IntegracaoSistema />
    </>
  );
}

export default ContentTecnologia;
