import React from 'react';
import PropTypes from 'prop-types';
import './contatos.scss';
import arrow from '../../../assets/img/arrow.svg';
import whatsapp from '../../../assets/img/whatsapp.svg';
import ask from '../../../assets/img/ask.svg';

function Contatos({ showModalEvent }) {
  const redirectWhatsapp = () => window.location.assign(
    'http://api.whatsapp.com/send?1=pt_BR&phone=5585996398268',
  );
  return (
    <div className="container">
      <div className="texts">
        <h1>Você também pode entrar em contato conosco para: </h1>
        <span>Ou, se preferir, mande um e-mail para</span>
        <p>contato@medflowtech.com</p>
      </div>
      <div className="box-actions">
        <div
          className="card-actions"
          onClick={redirectWhatsapp}
          aria-hidden="true"
        >
          <div className="icon-and-text">
            <img src={whatsapp} alt="" className="whatsapp" />
            <span>Tirar dúvida pelo whatsapp</span>
          </div>
          <div className="arrow">
            <img src={arrow} alt="" />
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="card-actions" onClick={showModalEvent}>
          <div className="icon-and-text">
            <img src={ask} alt="" className="ask" />
            <span>Tornar-se um parceiro Medflow</span>
          </div>
          <div className="arrow">
            <img src={arrow} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

Contatos.propTypes = {
  showModalEvent: PropTypes.func.isRequired,
};

export default Contatos;
