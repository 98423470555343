/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../assets/img/close_icon.svg';

function ModalConfirmacao({ show, onClose }) {
  const [firstRender, setFirstRender] = useState(true);
  const modal = useRef(null);
  const modalBody = useRef(null);

  const openModal = () => {
    document.body.classList.toggle('no-overflow');
    modal.current.classList.toggle('modal--open');
    modalBody.current.classList.toggle('modal__body--open');
  };

  const hideModal = () => {
    modalBody.current.classList.toggle('modal__body--close');
  };

  const closeModalAnimationEnd = (event) => {
    if (event.animationName === 'closeModal') {
      document.body.classList.toggle('no-overflow');
      modal.current.classList.toggle('modal--open');
      modalBody.current.classList.toggle('modal__body--close');
      modalBody.current.classList.toggle('modal__body--open');
    }
  };

  const onClickClose = (event) => {
    event.preventDefault();
    onClose(event);
  };

  const clickOutside = (event) => {
    if (event.target.id === 'modal-confirmacao') {
      onClose(event);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!firstRender) {
      return show ? openModal() : hideModal();
    }
    setFirstRender(false);
    // eslint-disable-next-line
  }, [show])

  return (
    <div
      id="modal-confirmacao"
      ref={modal}
      className="modal"
      onAnimationEnd={(event) => closeModalAnimationEnd(event)}
      onClick={(event) => {
        clickOutside(event);
      }}
    >
      <div className="modal__body" ref={modalBody}>
        <div className="modal-confirmacao">
          <div className="modal-confirmacao__header">
            <img
              className="modal-confirmacao__header__close"
              src={closeIcon}
              onClick={onClickClose}
              alt="Close"
            />
          </div>
          <div className="modal-confirmacao__body">
            <div>
              <h1 className="modal-confirmacao__body__title">Obrigado!</h1>
              <p className="modal-confirmacao__body__subtitle">
                Em breve entraremos em contato para te contar
                mais detalhes sobre o Medflow Enterprise.
              </p>
              <button type="submit" onClick={onClose}>OK</button>
            </div>
          </div>
          <div className="modal-confirmacao__spacer" />
        </div>
      </div>
    </div>
  );
}

/** Props Model */
ModalConfirmacao.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalConfirmacao;
