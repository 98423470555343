import React from 'react';
import CardInformacao from '../cardInformacao';
import qrCodeIcon from '../../../assets/img/qr-code-icon.svg';
import heartIcon from '../../../assets/img/heart-icon.svg';
import graphicIcon from '../../../assets/img/graphic-icon.svg';
import './Diferenciais.scss';

function Diferenciais() {
  return (
    <section className="section-diferenciais">
      <h1 className="section-diferenciais__title">Diferenciais da Tecnologia Medflow.</h1>
      <div className="cards-diferenciais">
        <CardInformacao
          key="card-1"
          icon={qrCodeIcon}
          title={(
            <>
              Plataforma
              {' '}
              <br />
              {' '}
              Low-code.
            </>
          )}
          text="Abordagem low-code para criar e evoluir condutas de forma simples com conteúdo padrão ou customizado pela instituição de saúde."
        />
        <CardInformacao
          key="card-1"
          icon={heartIcon}
          title={(
            <>
              Care
              {' '}
              <br />
              {' '}
              Pathway.
            </>
          )}
          text="Verificação da compatibilidade entre condutas por meio de modelos matemáticos para garantir a segurança
          do paciente."
        />
        <CardInformacao
          key="card-1"
          icon={graphicIcon}
          title={(
            <>
              Análise
              {' '}
              <br />
              {' '}
              de dados.
            </>
          )}
          text="Análise de dados para um indivíduo ou população e indicadores de adesão
          e aderência."
        />
      </div>
    </section>
  );
}

export default Diferenciais;
